
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class CardWidget extends Vue {
    @Prop({ type: String, default: "", required: false }) readonly CSV!: string;
    @Prop({ type: String, default: "", required: false })
    readonly variable!: string;
    @Prop({ type: String, default: "", required: false })
    readonly info!: string;
    @Prop({ type: String, default: "", required: false })
    readonly reference!: string;

    downloadCSV() {
        const anchor = document.createElement("a");
        anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(this.CSV);
        anchor.target = "_blank";
        anchor.download = this.variable + ".csv";
        anchor.click();
    }

    get isRowFullscreen() {
        return !!document.fullscreenElement;
    }

    // findRefByName(refName) {
    //     let obj: any = this;
    //     while (obj) {
    //         if (obj.$refs[refName]) {
    //             return obj.$refs[refName];
    //         }
    //         obj = obj.$parent;
    //     }
    //     return undefined;
    // }

    toggleRowFullscreen() {
        const elem = document.getElementById(this.reference);
        if (elem) {
            if (!document.fullscreenElement) {
                elem.requestFullscreen().catch(err => {
                    console.error(
                        `Error attempting to enable full-screen mode: ${err.message}`
                    );
                });
            } else {
                document.exitFullscreen();
            }
        }
    }
}
